<template>
  <div :class="animation">
    <b-card title="Document de validaton">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Type de document">
            <b-form-select
              v-model="$v.doc_type.$model"
              :class="{
                'is-invalid': $v.doc_type.$error,
              }"
              :options="typeOptions"
            />
            <div
              v-if="$v.doc_type.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.doc_type.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nom">
            <b-form-input
              v-model="$v.name.$model"
              :formatter="formatter"
              :class="{
                'is-invalid': $v.name.$error,
              }"
            />
            <div
              v-if="$v.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.name.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Description">
            <b-form-input
              v-model="$v.description.$model"
              :class="{
                'is-invalid': $v.description.$error,
              }"
            />
            <div
              v-if="$v.description.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.description.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Appliqué ?">
            <b-form-checkbox
              v-model="state"
              :value="true"
              :unchecked-value="false"
            >
              <span>{{ state ? 'Oui' : 'Non' }}</span>
            </b-form-checkbox>
            <div
              v-if="$v.state.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.state.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-center mb-2">
            <b-spinner
              v-if="process"
              variant="primary"
            />
          </div>
          <b-button
            variant="secondary"
            class="float-left"
            :disabled="process"
            @click="reset"
          >
            Rafraichir
          </b-button>
          <b-button
            variant="outline-primary"
            class="float-right"
            :disabled="process"
            @click="tryToSave"
          >
            <span>Enrégistrer</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      doc_type: null,
      name: null,
      description: null,
      state: false,
      typeOptions: [{ value: null, text: 'Sélectionner' }, { value: 'ID', text: "Pièce d'identité" }, { value: 'FACE_ID', text: "Selfie + Pièce d'identité" }],
    }
  },
  validations: {
    doc_type: {
      required,
    },
    name: {
      required,
    },
    description: {
      required,
    },
    state: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'addRequireDocumentSuccess',
      error: 'addRequireDocumentError',
      process: 'addRequireDocumentProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: 'Document ajouté avec succès.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'admin-all-kyc-documents' })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: "Une erreur est survenue lors de l'ajout.",
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['addRequireDocument']),
    formatter(value) {
      return value.toUpperCase()
    },
    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.addRequireDocument({
        doc_type: this.doc_type,
        name: this.name,
        description: this.description,
        state: this.state,
      })
    },
    reset() {
      this.doc_type = null
      this.name = null
      this.description = null
      this.state = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
  },
}
</script>

<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
